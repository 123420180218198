import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { versions } from '@assets/versions';

if (environment.production && environment.envName !== 'local_dev') {
  Sentry.init({
    dsn: 'https://9e67daae0a884c1390c4239bbd8c34f7@o516287.ingest.sentry.io/5647634',
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: [
          'localhost',
          'https://admin.dev.pickwings.ch',
          'https://admin.staging.pickwings.ch',
          'https://admin.pickwings.ch',
        ],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    release: versions.hash,
    dist: new Date(versions.date)
      .toISOString()
      .replace(/-/g, '')
      .replace(/T/g, '')
      .replace(/Z/g, '')
      .replace(/:/g, '')
      .replace('.', ''),
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.01,
    environment: environment.envName,
  });
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((success) => {
    console.log(`Bootstrap success: ${versions.hash}, ${versions.date}`);
    console.log(
      `🚚 🚚 🚚 Welcome to Pickwings!\nDoes this page need fixes or improvements? Send up some feedback at info@pickwings.ch!`
    );
  })
  .catch((err) => console.error(err));
