import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shipmentNumber',
})
export class ShipmentNumberPipe implements PipeTransform {
  transform(value: string | number): string {
    value = '' + (value || '');
    return !!value ? `${value.slice(0, 2)}-${value.slice(2, 6)}-${value.slice(6, 10)}-${value.slice(10)}` : '';
  }
}
