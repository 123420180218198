import { IEnvironment } from '@shared/core/interfaces/environment.interface';

export const environment: IEnvironment = {
  production: true,
  envName: 'prod',
  baseUrl: 'https://api.pickwings.ch',
  baseSocketUrl: 'wss://api.pickwings.ch',
  isBuildInfoShown: false,
  showUpdateAvailable: true,
  relateWebsite: {
    shipper: 'https://shipper.pickwings.ch/',
    ship: 'https://ship.pickwings.ch/account',
    carrier: 'https://carrier.pickwings.ch/',
    about: 'https://pickwings.ch/',
    old_shipper: 'https://ship.pickwings.ch/',
    old_admin: 'https://api.pickwings.ch/de/adminpanel//////',
    old_sysadmin: 'https://api.pickwings.ch/sysadmin//////'
  },
  firebase: {
    apiKey: 'AIzaSyDE3GoG4Tczcj2_yhsZgUhhDOmvIeEZJWw',
    authDomain: 'pickwings-webapps.firebaseapp.com',
    projectId: 'pickwings-webapps',
    storageBucket: 'pickwings-webapps.appspot.com',
    messagingSenderId: '108292520554',
    appId: '1:108292520554:web:fef7882a4fa8d62cbe2813',
    measurementId: 'G-GTV6R13PM5',
  },
  panelType: 'admin',
  defaultLanguage: 'de',
  languages: ['en', 'de'],
};
