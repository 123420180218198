import { DatePipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeEN from '@angular/common/locales/en';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAnalytics, APP_NAME } from '@angular/fire/compat/analytics';
import { AngularFireMessaging, AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { DateAdapter, MAT_DATE_FORMATS, MatNativeDateModule } from '@angular/material/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { PW_AVAILABLE_LANGUAGES, PW_DEFAULT_LANGUAGES, PwLocaleService } from '@pw-locale';
import { PW_TRANSLATION_DEFAULT_CONFIG, PW_TRANSLATION_ROOT_PATH, PwTranslateLoader } from '@pw-translate-loader';
import { AuthInterceptor } from '@pw-utils/interceptors/auth/auth.interceptor';
import { ErrorInterceptor } from '@pw-utils/interceptors/error/error.interceptor';
import { HealthStatusInterceptor } from '@pw-utils/interceptors/health-status/health-status.interceptor';
import { RefreshTokenInterceptor } from '@pw-utils/interceptors/refresh-token/refresh-token.interceptor';
import { ShipmentNumberPipe } from '@pw-utils/pipes/shipment-number/shipment-number.pipe';
import { BrowserNotificationsService } from '@pw-utils/services/browser-notifications/browser-notifications.service';
import * as Sentry from '@sentry/angular';
import { PwDateAdapter } from '@shared/core/classes/date-adapter.class';
import { PW_DATE_FORMATS } from '@shared/core/constants/datepicker-format.constant';
import { PW_ENVIRONMENT } from '@shared/core/constants/injectors.constant';
import { HealthCheckModule } from '@utils/src/health-check/health-check.module';
import { AppVersionInterceptor } from '@utils/src/utils/interceptors/app-version/app-version.interceptor';
import { ChartsModule } from 'ng2-charts';
import { ToastrModule } from 'ngx-toastr';
import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ThreeDayDashboardDataService } from './pages/three-day-dashboard/services/tree-day-dashboard/three-day-dashboard.service';

const isSupported = () => 'Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window;

@NgModule({
  declarations: [AppComponent],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    BrowserAnimationsModule,
    AngularFireMessagingModule,
    ChartsModule,
    AppRoutingModule,
    HttpClientModule,
    MatNativeDateModule,
    HealthCheckModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({ loader: { provide: TranslateLoader, useExisting: PwTranslateLoader } }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    DatePipe,
    PwLocaleService,
    ShipmentNumberPipe,
    PwTranslateLoader,
    PwDateAdapter,
    { provide: DateAdapter, useExisting: PwDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: PW_DATE_FORMATS },
    { provide: AngularFireAnalytics, useValue: null },
    { provide: PW_TRANSLATION_ROOT_PATH, useValue: '/assets/locale' },
    {
      provide: PW_TRANSLATION_DEFAULT_CONFIG,
      useValue: [
        { name: 'app', relateLocation: 'admin/app' },
        { name: 'common', relateLocation: 'shared/common' },
        { name: 'placeholder', relateLocation: 'shared/placeholder' },
        { name: 'toastr', relateLocation: 'shared/toastr' },
      ],
    },
    { provide: PW_DEFAULT_LANGUAGES, useValue: environment.defaultLanguage },
    { provide: PW_AVAILABLE_LANGUAGES, useValue: environment.languages },
    { provide: PW_ENVIRONMENT, useValue: environment },
    { provide: APP_NAME, useValue: 'admin' },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppVersionInterceptor,
      multi: true,
      deps: [
        APP_NAME
      ]
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HealthStatusInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptor, multi: true },
    ThreeDayDashboardDataService,
    { provide: ErrorHandler, useValue: Sentry.createErrorHandler({ showDialog: false }) },
    { provide: Sentry.TraceService, deps: [Router] },
    { provide: APP_INITIALIZER, useFactory: () => () => { }, deps: [Sentry.TraceService], multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private _locale: PwLocaleService,
    private _translate: TranslateService,
    private _browserNotificationsService: BrowserNotificationsService,
    private afMessaging: AngularFireMessaging
  ) {
    registerLocaleData(localeEN, 'en');
    if (isSupported()) {
      this._browserNotificationsService.init();
    } else {
      console.log('not enabling browser notifications');
    }
    this._locale.init();
    this._locale.listenLocale().subscribe(val => {
      console.log(`AppModule : listenLocale`);
      setTimeout(() => {
        this._translate.use('en');
        this._translate.use(val);
      }, 100);
    });

    window.navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
      console.log('service worker ready');
      try {
        this.requestPermission();
        this.listen();
      } catch (error) {
        console.error('error');
        console.error(error);
      }
    });
  }

  requestPermission() {
    this.afMessaging.requestToken
      .subscribe(
        (token) => { console.log('Permission granted! Save to the server!', token); },
        (error) => { console.error(error); },
      );
  }

  listen() {
    this.afMessaging.messages
      .subscribe(
        (message) => {
          console.log('message');
          console.log(message);
        }, (error) => {
          console.log('error');
          console.error(error);
        });
  }

}
