import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PrivateUrlConstantsService } from '@pw-utils/services/private-url-constants/private-url-constants.service';
import { Observable } from 'rxjs';
import { IThreeDayDashboardData } from '@shared/core/interfaces/tree-day-dashboard.interface';

@Injectable()
export class ThreeDayDashboardDataService {
  constructor(private _http: HttpClient, private _urls: PrivateUrlConstantsService) {}

  public getThreeDayDashboardData(): Observable<IThreeDayDashboardData> {
    return this._http.get<IThreeDayDashboardData>(this._urls.STATISTICS__THREE_DAY_DASHBOARD);
  }
}
