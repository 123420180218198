import { Inject, Injectable, Optional } from '@angular/core';
import { PW_ENVIRONMENT } from '@shared/core/constants/injectors.constant';
import { IEnvironment } from '@shared/core/interfaces/environment.interface';

@Injectable({
  providedIn: 'root',
})
export class PrivateUrlConstantsService {
  public SHIPMENTS = `${this.environment.baseUrl}/api/v3/private/shipments/`;
  public SHIPMENTS_FOR_APPROVAL = `${this.SHIPMENTS}release-shipments`;
  public ALERT_COUNT = `${this.environment.baseUrl}/api/v3/private/alert_count/`;
  public OPEN_SHIPMENTS = `${this.SHIPMENTS}open-shipments`;
  public SHIPMENT_ACTIVE_DRIVERS_LOCATION = `${this.environment.baseUrl}/api/v3/private/dash/active-drivers/`;
  public SHIPMENT_CLIENTS_LOCATION = `${this.environment.baseUrl}/api/v3/private/dash/clients/`;
  public OPEN_SHIPMENTS_LOCATION = `${this.environment.baseUrl}/api/v3/private/dash/open-shipments-map/`;
  public CURRENT_SHIPMENTS_LOCATION = `${this.environment.baseUrl}/api/v3/private/dash/current-shipments-map/`;
  public SHIPMENTS_WITH_ALERTS = `${this.SHIPMENTS}with-alerts/`;
  public SHIPMENTS_WITH_ALERTS_SENDERS = `${this.SHIPMENTS_WITH_ALERTS}senders/`;
  public SHIPMENTS_WITH_ALERTS_CARRIERS = `${this.SHIPMENTS_WITH_ALERTS}carriers/`;
  public SHIPMENTS_TIMED_DELIVERY = `${this.SHIPMENTS}timed-delivery`;
  public DRAFT_SHIPMENTS = `${this.environment.baseUrl}/api/v3/private/draft-shipments/`;
  public TICKETS = `${this.environment.baseUrl}/api/v3/private/tickets/`;
  public INTERNAL_TICKETS = `${this.environment.baseUrl}/api/v3/private/internal-ticket/`;
  public GET_NEXT_TICKET = `${this.TICKETS}get_next_ticket/`;
  public ADMIN_PAYOUTS = `${this.environment.baseUrl}/api/v3/private/payouts/`;
  public INVOICE_OVERVIEW = `${this.environment.baseUrl}/api/v3/private/billing/overview`;
  public INVOICES = `${this.environment.baseUrl}/api/v3/private/billing/invoices/`;
  public EXPENSES = `${this.environment.baseUrl}/api/v3/private/billing/expenses`;
  public ADDITIONAL_INVOICE_ITEMS = `${this.environment.baseUrl}/api/v3/private/billing/additional-invoice-items`;
  public EXPENSES_BY_ID = (id) => `${this.environment.baseUrl}/api/v3/private/billing/expenses/${id}`;
  public INVOICE_TOTAL = `${this.INVOICES}get-total`;
  public INVOICE_REMINDERS = `${this.environment.baseUrl}/api/v3/private/billing/invoice-reminders`;
  public INVOICE_REMINDERS_TOTAL = `${this.INVOICE_REMINDERS}/get-total`;
  public BERS_STATEMENTS = `${this.environment.baseUrl}/api/v3/private/billing/bank-statements/besr-statements/`;
  public BANK_STATEMENTS = `${this.environment.baseUrl}/api/v3/private/billing/bank-statements/bank-statements/`;
  public Z54_STATEMENTS = `${this.environment.baseUrl}/api/v3/private/billing/bank-statements/z54-statements/`;
  public MANUAL_INVOICE = `${this.environment.baseUrl}/api/v3/private/billing/invoices/manual-invoice/`;
  public PAYOUT_CLIENTS = `${this.ADMIN_PAYOUTS}clients/`;
  public PAYOUTS_OVERVIEW = `${this.ADMIN_PAYOUTS}overview/`;
  public PAYOUTS_RELEASE = `${this.ADMIN_PAYOUTS}release/`;
  public CREATE_PAYOUTS = `${this.ADMIN_PAYOUTS}payouts-create/`;
  public CREATE_PAYOUT = `${this.ADMIN_PAYOUTS}payout-create/`;
  public PAYOUTS_NEXT_PAYOUTS = `${this.ADMIN_PAYOUTS}next-payouts`;
  public PAYOUTS_NEXT_PAYOUTS_AGGREGATE = `${this.PAYOUTS_NEXT_PAYOUTS}/aggregate`;
  public SEND_NEXT_PAYOUTS_DEVIATION_MAIL = `${this.PAYOUTS_NEXT_PAYOUTS}/send-deviation-mail/`;
  public DRIVER_LOCATIONS = `${this.environment.baseUrl}/api/v3/private/drivers/locations`;
  public PRIVATE_INCIDENTS = `${this.environment.baseUrl}/api/v3/private/incidents`;
  public ZEDEV_INVOICES = `${this.environment.baseUrl}/api/v3/private/zedev-invoices`;
  public HIDDEN_USERS = `${this.environment.baseUrl}/api/v3/private/hidden-users`;
  public OLD_HIDDEN_USERS = `${this.environment.baseUrl}/api/v3/private/old-hidden-users`;
  public OLD_HIDDEN_USERS_IMPERSONATE = `${this.OLD_HIDDEN_USERS}/impersonate`;
  public OLD_HIDDEN_USERS_CLIENTS = `${this.OLD_HIDDEN_USERS}/clients`;
  public PRIVATE_USERS = `${this.environment.baseUrl}/api/v3/private/users`;
  public CLIENTS = `${this.environment.baseUrl}/api/v3/private/clients/`;
  public CLIENTS_NAMES = `${this.CLIENTS}names/`;
  public CLIENTS_LOCATIONS = `${this.CLIENTS}locations/`;
  public CLIENTS_DATES = `${this.CLIENTS}dates/`;
  public PRICE_FACTORS = `${this.environment.baseUrl}/api/v3/private/price-factors/additional-prices-and-options`;
  public PRICE_FACTORS_PALLET_PRICES = `${this.environment.baseUrl}/api/v3/private/price-factors/pallet-prices`;
  public ALL_DRIVER_IMAGES = `${this.environment.baseUrl}/api/v3/private/driver-images/`;
  public STATISTICS = `${this.environment.baseUrl}/api/v3/private/statistics`;
  public STATISTICS__THREE_DAY_DASHBOARD = `${this.STATISTICS}/three-day-dashboard/`;
  public STATISTICS__INACTIVE_CLIENTS = `${this.STATISTICS}/inactive-clients`;
  public STATISTICS__NEW_CLIENTS = `${this.STATISTICS}/new-clients`;
  public STATISTICS__LAST_LOGINS = `${this.STATISTICS}/last-logins`;
  public STATISTICS__CLIENT_NOTES = `${this.STATISTICS}/client-notes`;
  public STATISTICS__ASTAG_GU_PRICING = `${this.STATISTICS}/astag-gu`;
  public STATISTICS__CARGO_LOGISTIK_PRICING = `${this.STATISTICS}/cargo-logistik`;
  public STATISTICS__NEW_CLIENTS_CLIENT_SHEET = `${this.STATISTICS}/new-clients-client-sheet`;
  public STATISTICS__IMPORTANT_CLIENTS = `${this.STATISTICS}/important-clients`;
  public STATISTICS__OVERVIEW = `${this.STATISTICS}/overview/`;
  public STATISTICS__DIVERSITY = `${this.STATISTICS}/diversity/`;
  public SEND_PUSH_NOTIFICATION = `${this.environment.baseUrl}/api/v3/private/maintenance/test_send_push_notification`;
  public SEND_EMAIL = `${this.environment.baseUrl}/api/v3/private/maintenance/test_send_email`;
  public SEND_WEBSOCKET_EVENT = `${this.environment.baseUrl}/api/v3/private/maintenance/test_send_websocket_event`;
  public WEBSOCKET_CONNECTIONS = `${this.environment.baseUrl}/api/v3/private/users/ws/subscribed`;
  public ACTIVITY_EVENTS = `${this.environment.baseUrl}/api/v3/private/maintenance/activity_events`;
  public GENERATE_ENVELOPE = `${this.environment.baseUrl}/api/v3/private/pdf-generation/envelope`;
  public GENERATE_DELIVERY_EMPTY_ORDERS = `${this.environment.baseUrl}/api/v3/private/pdf-generation/delivery`;
  public GENERATE_PICKUP_EMPTY_ORDERS = `${this.environment.baseUrl}/api/v3/private/pdf-generation/pickup`;
  public PRESALES = `${this.environment.baseUrl}/api/v3/private/pre-sales/`;
  public EXPORT_SHIPMENTS = `${this.environment.baseUrl}/api/v3/private/exports/export-shipments/`;
  public EXPORT_SHIPMENTS_V2 = `${this.environment.baseUrl}/api/v3/private/exports/export-shipments-v2/`;
  public EMPTIES_EXCHANGE = `${this.environment.baseUrl}/api/v3/private/empties-exchange/`;
  public DOCUMENTS_EMPTY_CONTAINERS_PICKUP = `${this.environment.baseUrl}/api/v3/private/exports/pdf-empties-pickup`;
  public DOCUMENTS_EMPTY_CONTAINERS_DELIVERY = `${this.environment.baseUrl}/api/v3/private/exports/pdf-empties-delivery`;

  constructor(@Optional() @Inject(PW_ENVIRONMENT) public environment: IEnvironment) { }

  public PRICE_FACTORS_PALLET_PRICES_BY_ID = (id) => `${this.environment.baseUrl}/api/v3/private/price-factors/pallet-prices/${id}`;

  public PAYOUT_RELEASE = (clientId: number) => `${this.ADMIN_PAYOUTS}clients/${clientId}/release-payouts/`;

  public ASSIGN_SENDER_TO_EMPTIES_EXCHANGE = (siteId: number) => `${this.EMPTIES_EXCHANGE}${siteId}/assign-sender/`;

  public COMBINE_EMPTIES_EXCHANGES = (siteId: number) => `${this.EMPTIES_EXCHANGE}${siteId}/combine/`;

  public DRAFT_SHIPMENT_BY_NUMBER = (draftNumber: string) => `${this.DRAFT_SHIPMENTS}${draftNumber}/`;

  public PRIVATE_USER_BY_ID = (userId: number) => `${this.PRIVATE_USERS}/${userId}`;

  public PRIVATE_USER_BY_ID_LOCATIONS = (userId: number) => `${this.PRIVATE_USERS}/${userId}/last-positions`;

  public ZEDEV_PERIOD = (year: number, week: number) => `${this.ZEDEV_INVOICES}/details/${year}/${week}`;

  public ZEDEV_EXPORT = (year: number, week: number) => `${this.ZEDEV_INVOICES}/export/${year}/${week}`;

  public ZEDEV_PDF_INVOICE_EXPORT = (invoiceNumber: string) => `${this.ZEDEV_INVOICES}/export-pdf/${invoiceNumber}`;

  public ZEDEV_EXCEL_INVOICE_EXPORT = (invoiceNumber: string) => `${this.ZEDEV_INVOICES}/export-excel/${invoiceNumber}`;

  public ZEDEV_INVOICE_PAID = (invoiceNumber: string) => `${this.ZEDEV_INVOICES}/paid/${invoiceNumber}`;

  public CLIENT_BY_ID = (clientId: number) => `${this.CLIENTS}${clientId}/`;

  public CLIENT_DATA = (clientId: number) => `${this.CLIENTS}${clientId}/client-data`;

  public CLIENT_INTERNAL_NOTE = (clientId: number) => `${this.CLIENTS}${clientId}/internal-note`;

  public CLIENT_INVOICE_DATA = (clientId: number) => `${this.CLIENTS}${clientId}/invoice-data`;

  public CLIENT_WIZARD = (clientId: number) => `${this.CLIENTS}${clientId}/wizard`;

  public CLIENT_CARRIER_DATA = (clientId: number) => `${this.CLIENTS}${clientId}/carrier-data`;

  public CLIENT_PRICE_MODE = (clientId: number) => `${this.CLIENTS}${clientId}/price-calculator`;

  public CLIENT_SURCHARGES = (clientId: number) => `${this.CLIENTS}${clientId}/additions`;

  public CLIENT_PIPELINE = (clientId: number) => `${this.CLIENTS}${clientId}/pipeline`;

  public ADDITIONAL_CLIENT_DATA = (clientId: number) => `${this.CLIENTS}${clientId}/other`;

  public INVOICE_BY_NUMBER = (invoiceNumber: string) => `${this.INVOICES}${invoiceNumber}/`;

  public ADDITIONAL_INVOICE_ITEMS_BY_INVOICE_NUMBER = (invoiceNumber: string) => `${this.INVOICES}${invoiceNumber}/additional-items/`;

  public ADDITIONAL_INVOICE_ITEM_BY_ID = (invoiceNumber: string, itemId: number) =>
    `${this.INVOICES}${invoiceNumber}/additional-items/${itemId}/`;

  public INVOICE_SHIPMENTS = (invoiceNumber: string) => `${this.INVOICES}${invoiceNumber}/shipments/`;

  public PAID_INVOICE_BY_ID = (invoiceId: number) => `${this.INVOICES}${invoiceId}/paid`;

  public CLIENT_HUBS = (clientId: number) => `${this.CLIENT_BY_ID(clientId)}hubs/`;

  public CLIENT_EMPTY_CONTAINERS = (clientId: number) => `${this.CLIENT_BY_ID(clientId)}empty-postings/`;

  public SET_CLIENT_EMPTY_CONTAINERS_BALANCE = (clientId: number, siteId: number) =>
    `${this.CLIENT_EMPTY_CONTAINERS(clientId)}${siteId}/set-correct-balance/`;

  public GET_CLIENT_EMPTY_CONTAINERS_EXCEL = (clientId: number, siteId: number) =>
    `${this.CLIENT_EMPTY_CONTAINERS(clientId)}${siteId}/export-excel/`;

  public CLIENT_DELIVERY_LOCATIONS = (clientId: number) => `${this.CLIENT_BY_ID(clientId)}delivery-locations-map/`;
  public CLIENT_ADDITIONAL_PRICES_AND_OPTIONS = (clientId: number) =>
    `${this.CLIENT_BY_ID(clientId)}additional_options_and_prices/`;

  public CLIENT_PREFERENCES = (clientId: number) =>
    `${this.CLIENT_BY_ID(clientId)}preferences/`;

  public ACTIVATION_EMAIL = (clientId: number) => `${this.CLIENT_BY_ID(clientId)}send-activation-email/`;
  public ACTIVATE_CLIENT = (clientId: number) => `${this.CLIENT_BY_ID(clientId)}activate/`;
  public CLIENT_CHARGE_PROVIDER = (clientId: number) => `${this.CLIENT_BY_ID(clientId)}charge_provider_status/`;

  public DEACTIVATE_CLIENT = (clientId: number) => `${this.CLIENT_BY_ID(clientId)}deactivate/`;

  public CLIENT_RELATED_USERS = (clientId: number) => `${this.CLIENT_BY_ID(clientId)}users/`;

  public CLIENT_PAYOUTS = (clientId: number) => `${this.CLIENT_BY_ID(clientId)}payouts/`;

  public CLIENT_PAYOUTS_AGGREGATE = (clientId: number) => `${this.CLIENT_BY_ID(clientId)}payouts/aggregate/`;

  public CLIENT_INVOICES = (clientId: number) => `${this.CLIENT_BY_ID(clientId)}invoices/`;

  public CLIENT_INVOICES_AGGREGATE = (clientId: number) => `${this.CLIENT_BY_ID(clientId)}invoices/aggregate/`;

  public PAYOUTS_FILE = (id: number | string, fileType: 'pain' | 'pdf') => `${this.ADMIN_PAYOUTS}${id}/${fileType}/`;

  public NEXT_PAYOUTS_BY_CLIENT_ID = (id: number) => `${this.PAYOUTS_NEXT_PAYOUTS}/${id}`;

  public NEXT_CLIENT_PAYOUTS_INVOICES = (id: number) =>
    `${this.PAYOUTS_NEXT_PAYOUTS}/unpaidout-additional-invoices/${id}`;

  public LOCK_SHIPMENT_NEXT_PAYOUTS = (clientId: number, shipmentId: number) =>
    `${this.PAYOUTS_NEXT_PAYOUTS}/shipment/${clientId}/${shipmentId}/lock`;

  public UNLOCK_SHIPMENT_NEXT_PAYOUTS = (clientId: number, shipmentId: number) =>
    `${this.PAYOUTS_NEXT_PAYOUTS}/shipment/${clientId}/${shipmentId}/unlock`;

  public UNLOCK_ALL_NEXT_PAYOUTS = (clientId: number) => `${this.PAYOUTS_NEXT_PAYOUTS}/shipment/${clientId}/unlock-all`;
  public LOCK_UNLOCK_SHIPMENTS_FILTERED_NEXT_PAYOUTS = `${this.PAYOUTS_NEXT_PAYOUTS}/shipments/lock-unlock`;

  public NEXT_CLIENT_PAYOUTS_SHIPMENTS = (id: number) => `${this.PAYOUTS_NEXT_PAYOUTS}/unpaidout-shipments/${id}`;
  public NEXT_CLIENT_PAYOUTS_SHIPMENTS_EXPORT = (id: number) => `${this.PAYOUTS_NEXT_PAYOUTS}/unpaidout-shipments/${id}/export`;

  public CANCEL_SHIPMENT = (numb: string) => `${this.SHIPMENTS}cancel/${numb}/`;

  public SHIPMENT_BY_NUMBER = (shipmentNumber: string) => `${this.SHIPMENTS}${shipmentNumber}/`;

  public SHIPMENT_HISTORY = (shipmentNumber: string) => `${this.SHIPMENT_BY_NUMBER(shipmentNumber)}shipment_history/`;

  public SHIPMENT_CHANGES_HISTORY = (shipmentNumber: string) =>
    `${this.SHIPMENT_BY_NUMBER(shipmentNumber)}edit-history-events/`;

  public SHIPMENT_EFFORTS = (shipmentNumber: string) =>
    `${this.SHIPMENT_BY_NUMBER(shipmentNumber)}shipment_additional_items/`;

  public SHIPMENT_EFFORT = (shipmentNumber: string, effortId: number) =>
    `${this.SHIPMENT_EFFORTS(shipmentNumber)}${effortId}/`;

  public SHIPMENT_ANNOTATIONS = (shipmentNumber: string) =>
    `${this.SHIPMENT_BY_NUMBER(shipmentNumber)}shipment_annotations/`;

  public SHIPMENT_TICKET_ANNOTATIONS = (shipmentNumber: string) =>
    `${this.SHIPMENT_BY_NUMBER(shipmentNumber)}ticket_annotations/`;

  public SHIPMENT_HISTORY_ANNOTATIONS = (shipmentNumber: string) =>
    `${this.SHIPMENT_BY_NUMBER(shipmentNumber)}edit-history-event-annotations/`;

  public SHIPMENT_INTERNAL_TICKET_ANNOTATIONS = (shipmentNumber: string) =>
    `${this.SHIPMENT_BY_NUMBER(shipmentNumber)}internal-ticket-annotations/`;

  public GET_TICKET_BY_SHIPMENT_NUMBER = (shipmentNumber: string) =>
    `${this.environment.baseUrl}/api/v3/private/internal-ticket/${shipmentNumber}/`;

  public SHIPMENT_DELIVERY_IMAGES = (shipmentNumber: string) =>
    `${this.SHIPMENT_BY_NUMBER(shipmentNumber)}delivery_images/`;

  public SHIPMENT_CARGO_ITEMS = (shipmentNumber: string) => `${this.SHIPMENT_BY_NUMBER(shipmentNumber)}cargo-items/`;

  public SHIPMENT_CARGO_ITEM = (shipmentNumber: string, itemId: number) =>
    `${this.SHIPMENT_CARGO_ITEMS(shipmentNumber)}${itemId}/`;

  public SHIPMENT_PICK_IMAGES = (shipmentNumber: string) => `${this.SHIPMENT_BY_NUMBER(shipmentNumber)}pick_images/`;

  public SHIPMENT_FILES = (shipmentNumber: string) => `${this.SHIPMENT_BY_NUMBER(shipmentNumber)}files/`;

  public SHIPMENT_PACKETS = (shipmentNumber: string) => `${this.SHIPMENT_BY_NUMBER(shipmentNumber)}packets/`;

  public SHIPMENT_PACKET_TYPES = (shipmentNumber: string) => `${this.SHIPMENT_BY_NUMBER(shipmentNumber)}packet-types/`;

  // public SHIPMENT_BY_NUMBER = (shipmentNumber: string) => `${this.SHIPMENTS}get-by-number/${shipmentNumber}/`;
  public CHANGE_SHIPMENT_DELIVERY_STATUS = (shipmentNumber: string) =>
    `${this.SHIPMENT_BY_NUMBER(shipmentNumber)}change_delivery_status/`;

  public CHANGE_SHIPMENT_PRICES = (shipmentNumber: string) => `${this.SHIPMENT_BY_NUMBER(shipmentNumber)}edit_prices/`;

  public CHANGE_SHIPMENT_PRICE = (shipmentNumber: string) =>
    `${this.SHIPMENT_BY_NUMBER(shipmentNumber)}recalculate-shipment-price/`;

  public CHANGE_SHIPMENT_CARRIER_PRICE = (shipmentNumber: string) =>
    `${this.SHIPMENT_BY_NUMBER(shipmentNumber)}recalculate_carrier_price/`;

  public REMOVE_SHIPMENT_CARRIER = (shipmentNumber: string) =>
    `${this.SHIPMENT_BY_NUMBER(shipmentNumber)}remove-carrier/`;

  public DUPLICATE_SHIPMENT = (shipmentNumber: string) =>
    `${this.SHIPMENT_BY_NUMBER(shipmentNumber)}duplicate`;

  public RETURN_SHIPMENT = (shipmentNumber: string) =>
    `${this.SHIPMENT_BY_NUMBER(shipmentNumber)}return-shipment`;

  public MARK_SHIPMENT = (shipmentNumber: string) => `${this.SHIPMENT_BY_NUMBER(shipmentNumber)}flag-shipment-rules/`;

  public SHIPMENT_SEND_DEVIATION_MAIL = (shipmentNumber: string) =>
    `${this.SHIPMENT_BY_NUMBER(shipmentNumber)}send_deviation_mail/`;

  public SHIPMENT_EMPTY_PICKUP = (shipmentNumber: string) =>
    `${this.SHIPMENT_BY_NUMBER(shipmentNumber)}edit-empties-pickup/`;

  public SHIPMENT_EMPTY_DELIVERY = (shipmentNumber: string) =>
    `${this.SHIPMENT_BY_NUMBER(shipmentNumber)}edit-empties-delivery/`;

  public UPLOAD_SHIPMENT_IMAGES = (shipmentNumber: string) =>
    `${this.SHIPMENT_BY_NUMBER(shipmentNumber)}upload_shipment_images/`;

  public UPLOAD_SHIPMENT_IMAGE = (shipmentNumber: string) =>
    `${this.SHIPMENT_BY_NUMBER(shipmentNumber)}upload_shipment_image/`;

  public SHIPMENT_TOGGLE_NEXT_PAYMENT_FLAG = (shipmentNumber: string) =>
    `${this.SHIPMENT_BY_NUMBER(shipmentNumber)}toggle_next_payment_flag/`;

  public SHIPMENTS_ADD_ALERTS_COMMENT = (shipmentNumber: string) =>
    `${this.SHIPMENTS_WITH_ALERTS}${shipmentNumber}/add-comment/`;

  public SHIPMENTS_EDIT_METADATA = (shipmentNumber: string) =>
    `${this.SHIPMENT_BY_NUMBER(shipmentNumber)}update-metadata/`;

  public EDIT_SHIPMENT_DELIVERY_ADDRESS = (shipmentNumber: string) =>
    `${this.SHIPMENT_BY_NUMBER(shipmentNumber)}edit-delivery-address`;

  public EDIT_SHIPMENT_PICKUP_ADDRESS = (shipmentNumber: string) =>
    `${this.SHIPMENT_BY_NUMBER(shipmentNumber)}edit-pickup-address`;

  public EDIT_SHIPMENT_OPTIONS_DIRECTLY = (shipmentNumber: string) =>
    `${this.SHIPMENT_BY_NUMBER(shipmentNumber)}edit-options-directly`;

  public DELETE_SHIPMENT_IMAGE = (shipmentNumber: string, imageId: number) =>
    `${this.SHIPMENT_BY_NUMBER(shipmentNumber)}images/${imageId}/delete`;

  public EDIT_SHIPMENT_DATES = (shipmentNumber: string) => `${this.SHIPMENT_BY_NUMBER(shipmentNumber)}edit-dates`;

  public EDIT_SHIPMENT_OPTIONS = (shipmentNumber: string) => `${this.SHIPMENT_BY_NUMBER(shipmentNumber)}edit-options`;

  public TICKET_BY_ID = (id: number) => `${this.TICKETS}${id}/`;

  public TICKET_ANNOTATIONS = (id: number) => `${this.TICKET_BY_ID(id)}ticket_text_annotations/`;

  public INTERNAL_TICKET_ANNOTATIONS = (id: number) => `${this.INTERNAL_TICKETS}${id}/annotations/`;

  public ADD_INTERNAL_TICKET_ANNOTATIONS = (id: number) => `${this.INTERNAL_TICKETS}${id}/add-annotation/`;

  public TICKET_ANNOTATION = (id: number) => `${this.TICKET_BY_ID(id)}ticket_text_annotation/`;

  public CLOSE_TICKET = (id: number) => `${this.TICKET_BY_ID(id)}close-ticket/`;

  public STATISTICS__CLIENT_OVERVIEW = (clientId: number) => `${this.CLIENT_BY_ID(clientId)}statistic/`;
  public PRESALE_ACTIONS = (presaleNumber: string) => `${this.PRESALES}admin-panel/actions/${presaleNumber}`;
  public PRESALE_BY_NUMBER = (presaleNumber: string) => `${this.PRESALES}${presaleNumber}`;

  public SHIPMENTS_FOR_APPROVAL_BY_NUMBER = (shipmentNumber: string) =>
    `${this.environment.baseUrl}/api/v3/private/shipments/approval/${shipmentNumber}`;

  public BESR_STATEMENTS_PROCESS_NO_DATA = (id: number) => `${this.BERS_STATEMENTS}${id}/process-no-data/`;
  public BESR_STATEMENTS_PROCESS_INVOICE = (id: number) => `${this.BERS_STATEMENTS}${id}/process-invoice/`;
  public BANK_STATEMENTS_PROCESS_NO_DATA = (id: number) => `${this.BANK_STATEMENTS}${id}/process-no-data/`;
  public BANK_STATEMENTS_PROCESS_INVOICE = (id: number) => `${this.BANK_STATEMENTS}${id}/process-invoice/`;
  public Z54_STATEMENTS_PROCESS_NO_DATA = (id: number) => `${this.Z54_STATEMENTS}${id}/process-no-data/`;
  public Z54_STATEMENTS_PROCESS_INVOICE = (id: number) => `${this.Z54_STATEMENTS}${id}/process-invoice/`;
  public SET_INVOICE_COMMENT = (invoiceNumber: string) => `${this.INVOICES}${invoiceNumber}/set-comment/`;

  public DOCUMENT_EMPTY_CONTAINERS_DELIVERY = (shipmentNumber) =>
    `${this.environment.baseUrl}/api/v3/private/shipments/${shipmentNumber}/pdf-empties-delivery/`;


  public DRAFT_SHIPMENTS_BY_CLIENT_ID = (clientId) => `${this.DRAFT_SHIPMENTS}${clientId}`;

  public EMPTY_EXCHANGE_ARCHIVE_BY_CLIENT_ID = (clientId: number) =>
    `${this.CLIENT_BY_ID(clientId)}empty-postings-archive/`;

  public PRICE_FACTORS_BY_TYPE = (type: number) =>
    `${this.environment.baseUrl}/api/v3/private/price-factors/additional-prices-and-options-by-property/${type}`;

  public SHIPMENT_CARGO_ITEM_CALL_PRICE_EDIT = (shipmentNumber, cargoItemId) => `${this.SHIPMENTS}${shipmentNumber}/cargo-items/${cargoItemId}/edit/callprice`;
  public SHIPMENT_CARGO_ITEM_CALL_PRICE_ADD = (shipmentNumber) => `${this.SHIPMENTS}${shipmentNumber}/cargo-items/add/callprice`;

}
